import React from 'react';
import { parseCookies, setCookie } from 'nookies';
import dynamic from 'next/dynamic';
import { isNil } from 'ramda';
import useTranslation from 'next-translate/useTranslation';
import type { AppProps } from 'next/app';

import ServiceConfigs from 'service/config';
import { fetchStoreLayoutInfo } from 'service/ssr';
import { ContextRegistry } from 'common/lib/registries';
import { getFullDomain, getStoreSlug, isServerSide, logger } from 'common/utils';
import AppInitialization from 'common/components/AppInitialization';
import { Screen } from 'common/components';
import { LOCALIZATION_LANGUAGE_COOKIE_NAME, isLanguageNotAvailable } from 'common/lib/localization';
import { LANGUAGE_AR, LANGUAGE_EN, USER_AUTHENTICATION_COOKIE } from 'common/constants';
import appWithI18n from 'next-translate/appWithI18n';
import FulfillmentSettingsProvider from 'contexts/fulfillmentSettings/provider';
import { getOrderFastStore, getUrlTypeFlags } from '../common/utils';
import i18nConfig from '../../i18n';

const AnalyticsInitialization = dynamic(() => import('common/components/AnalyticsInitialization'), {
  ssr: false,
});

type AppPropsType = AppProps & {
  storeMainInfo;
  isManualOrder: boolean;
  serverError;
  userToken;
  subdomain: string;
  referenceId?: string;
  operatorId?: string;
  orderFastStore?: string;
  isOrderFastLandingPage: boolean;
  justAuthorized: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: React.ReactNode;
  privateKey?: string;
};

const MyApp = ({
  pageProps,
  Component,
  storeMainInfo,
  isManualOrder,
  serverError,
  userToken,
  subdomain,
  referenceId,
  operatorId,
  orderFastStore,
  isOrderFastLandingPage,
  justAuthorized,
  privateKey,
}: AppPropsType) => {
  const { lang } = useTranslation();
  const userTokenCookie = parseCookies(null)[USER_AUTHENTICATION_COOKIE];
  const { status } = serverError || {};
  ServiceConfigs.init({
    subdomain: status === 404 ? null : subdomain,
    userToken: userToken || userTokenCookie,
    orderFastStore,
    useDeliveryzonesMs: storeMainInfo?.setting?.useDeliveryzonesMs,
    multicountryEnabled: storeMainInfo?.setting?.multicountryEnabled,
    googleMapsApiKey: storeMainInfo?.setting?.googleMapsApiKey,
    serviceFeeEnabled: storeMainInfo?.setting?.serviceFeeEnabled,
    storeId: storeMainInfo?.id,
    privateKey,
  });

  return (
    <FulfillmentSettingsProvider>
      <AppInitialization
        storeMainInfo={storeMainInfo}
        status={status}
        isManualOrder={isManualOrder}
        referenceId={referenceId}
        operatorId={operatorId}
      />
      <AnalyticsInitialization />
      <Screen
        userToken={userToken}
        status={status}
        storeMainInfo={storeMainInfo}
        lang={lang}
        Component={Component}
        pageProps={pageProps}
        isOrderFastLandingPage={isOrderFastLandingPage}
        justAuthorized={justAuthorized}
      />
    </FulfillmentSettingsProvider>
  );
};
MyApp.getInitialProps = async appContext => {
  const { ctx, Component, router } = appContext;
  const { req, res } = ctx;

  // Check if the request is for the favicon
  if (req && req.url === '/favicon.ico') {
    res.statusCode = 204; // No Content
    res.end();
    return {};
  }

  let serverError;
  try {
    let storeMainInfo;
    ContextRegistry.setContext(appContext);
    const { token: urlQueryToken, referenceId, operatorId } = ctx?.query || {};
    const storeSlug = getStoreSlug();
    const { isManualOrder, isOrderFast } = getUrlTypeFlags();
    const userToken = urlQueryToken || parseCookies(ctx)[USER_AUTHENTICATION_COOKIE];
    const subdomain = storeSlug || getOrderFastStore();
    const orderFastStore = storeSlug ? null : subdomain;
    const isOrderFastLandingPage = isOrderFast && !subdomain && !storeSlug;
    const localeCookie = parseCookies(ctx)[LOCALIZATION_LANGUAGE_COOKIE_NAME];
    ServiceConfigs.init({
      subdomain,
      userToken,
      orderFastStore,
      privateKey: process.env.NEXT_PRIVATE_KEY as string,
    });

    if (isServerSide()) {
      try {
        storeMainInfo = await fetchStoreLayoutInfo();
      } catch (err) {
        if (err?.response.status !== 404) {
          serverError = JSON.parse(JSON.stringify(err, undefined, 2))?.response;
          logger.error({ error: 'ServerError in fetching store info', extraData: serverError });
        }
      }
      // If no locale available from the url, redirect on the saved locale or fallback to store preferred locale
      const languageNotAvailable = isLanguageNotAvailable(router.locale);
      // make sure it is in server only, not client not build time
      if (languageNotAvailable && ctx && ctx.res.writeHead) {
        const storeLanguage = storeMainInfo?.preferredLanguage === 'arabic' ? LANGUAGE_AR : LANGUAGE_EN;
        const redirectionLanguage = localeCookie ?? storeLanguage;

        ctx.res.writeHead(302, { Location: `/${redirectionLanguage}${ctx?.req?.url}` });
        ctx.res.end();
        return {};
      }
    }

    // Update locale cookie if it's not set or if it's outdated.
    if (isNil(localeCookie) || localeCookie !== router.locale) {
      setCookie(ctx, LOCALIZATION_LANGUAGE_COOKIE_NAME, router.locale, {
        path: '/', // Set cookie to match all app, default is to only match current page.
        maxAge: 365 * 24 * 60 * 60, // Save cookie for 1 year, default is to save until browser shutdown.
        domain: getFullDomain(),
      });
    }
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    // Anything returned here can be access by the client
    return {
      pageProps,
      serverError,
      locale: router.locale,
      storeMainInfo,
      userToken,
      isManualOrder,
      referenceId,
      subdomain,
      operatorId,
      orderFastStore,
      isOrderFastLandingPage,
      justAuthorized: !!urlQueryToken,
      privateKey: process.env.NEXT_PRIVATE_KEY as string,
    };
  } catch (error) {
    serverError = {
      status: 500,
      error,
    };
    logger.error({ error: 'App.js ServerError', extraData: serverError });
    return { serverError };
  }
};

export default appWithI18n(MyApp, {
  ...i18nConfig,
  skipInitialProps: false,
});
