import { gql } from 'graphql-request';

const authorizeCartMutation = gql`
  mutation ($subdomain: String!, $cartId: ID!, $isManualOrder: Boolean!) {
    authorizeCart(subdomain: $subdomain, cartId: $cartId, isManualOrder: $isManualOrder) {
      authorized
    }
  }
`;

export default authorizeCartMutation;
