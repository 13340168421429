import { useEffect, useState } from 'react';

import {
  usePersistedSelectedCoordinates,
  usePersistedSupportedDeliveryZone,
  usePersistedUserAddressId,
  usePersistedUserUmbrellaData,
} from 'common/hooks';
import { FULFILLMENT_MODES, URLS } from 'common/constants';
import ServiceConfigs from 'service/config';
import { AddressBookWithDeliveryZone } from 'common/types';
import { useDecideUserBook } from '.';
import { AddressBook, Car } from '../../../service/types';
import { useFulfillmentSettings } from '../../../contexts';
import { getClosestAddress } from '../utils';

type useHandleCheckoutRedirectionType = (shouldQuery?: boolean) => { isLoading: boolean; route: string };

const useHandleCheckoutRedirection: useHandleCheckoutRedirectionType = () => {
  const [persistedUmbrellaData] = usePersistedUserUmbrellaData();
  const [selectedCoords] = usePersistedSelectedCoordinates();
  const { area, orderMode } = useFulfillmentSettings();
  const [persistedUserAddressId] = usePersistedUserAddressId();
  const [persistedSupportedDzms] = usePersistedSupportedDeliveryZone();

  const useDeliveryzonesMs = ServiceConfigs?.getUseDeliveryzonesMs();

  const [route, setRoute] = useState(URLS.SHOPPING_CART);

  const isDelivery = orderMode === FULFILLMENT_MODES.DELIVERY;
  const isCarPickup = orderMode === FULFILLMENT_MODES.CAR_PICKUP;
  const isBeachPickup = orderMode === FULFILLMENT_MODES.BEACH;

  const { data: userBook, isLoading } = useDecideUserBook({
    isCarPickup,
    isDelivery,
    areaId: area?.id,
  });

  useEffect(() => {
    if (!userBook && !isBeachPickup) return;
    const closestAddress = getClosestAddress(
      (userBook as AddressBookWithDeliveryZone)?.addresses,
      selectedCoords,
      orderMode,
      persistedSupportedDzms,
    );
    if (isDelivery) {
      if (closestAddress || persistedUserAddressId) {
        setRoute(URLS.SHOPPING_CART);
        return;
      }
      if (useDeliveryzonesMs) {
        if (selectedCoords?.lat && selectedCoords?.lng) setRoute(URLS.NEW_ADDRESS);
        else setRoute(URLS.ORDER_DELIVERY);
      } else if (!(userBook as AddressBook)?.addresses?.length) setRoute(URLS.ADDRESS_MAP);
    } else if (isCarPickup && !(userBook as Array<Car>)?.length) setRoute(URLS.NEW_CAR);
    else if (isBeachPickup && !persistedUmbrellaData) setRoute(URLS.NEW_UMBRELLA);
  }, [userBook, isCarPickup, isDelivery, isBeachPickup, persistedUserAddressId]);

  return { isLoading, route };
};

export default useHandleCheckoutRedirection;
