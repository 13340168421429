module.exports = {
  locales: ['default', 'en', 'ar'],
  defaultLocale: 'default',
  localesToIgnore: ['default'],
  loader: false,
  pages: {
    '*': ['common', 'errorPages', 'errors', 'home', 'layout'],
    '/': ['search', 'product', 'userOrders', 'address', 'branches'],
    '/products/[id]': ['product', 'address', 'branches'],
    '/categories/[id]': ['product', 'branches'],
    '/search': ['search', 'product'],
    '/branches': ['branches'],
    '/branches/[id]': ['branches'],
    '/order-mode': ['address', 'branches', 'search', 'estimatedTime'],
    '/review-order': ['product'],
    '/shopping-cart': ['shoppingCart', 'address', 'checkout', 'product'],
    '/checkout': [
      'address',
      'shoppingCart',
      'payment',
      'product',
      'checkout',
      'car',
      'estimatedTime',
      'umbrella',
      'wallet',
      'branches',
    ],
    '/updates/[id]': ['payment', 'order', 'estimatedTime', 'product', 'address'],
    '/track-order': ['order'],
    '/status/[id]': ['order', 'address'],
    '/rider/[id]/order/[orderNumber]': ['rider', 'address'],
    '/gift-recipient': ['checkout'],
    '/payment-methods': ['payment, checkout'],
    '/user/address-map': ['address'],
    '/user/address': ['address'],
    '/user/address/[id]': ['address'],
    '/user/areas-list': ['address'],
    '/user/car': ['car'],
    '/orderfast-account': ['orderFastAccount'],
    '/wallet-transactions': ['wallet'],
    '/wallet-transactions/[id]': ['wallet'],
    '/estimated-time': ['estimatedTime', 'branches'],
    '/links': ['land'],
    '/sent-cart-link': ['order'],
    '/order/delivery': ['address', 'branches'],
    '/user/umbrella': ['umbrella'],
    '/user/profile': ['userProfile'],
    '/user/address-book': ['address'],
  },
  loadLocaleFrom: (locale, namespace) => import(`./locales/${locale}/${namespace}`).then(m => m.default),
  interpolation: { prefix: '@{', suffix: '}' },
};
