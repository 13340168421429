import authorizeCartMutation from '../../mutations/cart/authorizeCart';
import { apiMutate } from '../../utils/mutation';
import ServiceConfigs from '../../config';
import { Mutation } from '../../types';

type useAuthorizeCartType = () => ({ cartId, isManualOrder }: { cartId: string; isManualOrder: boolean }) => Promise<boolean>;

const useAuthorizeCart: useAuthorizeCartType =
  () =>
  ({ cartId, isManualOrder }) =>
    apiMutate({
      mutation: authorizeCartMutation,
      variables: { subdomain: ServiceConfigs.getSubDomain(), cartId, isManualOrder },
      requestHeaders: { ...ServiceConfigs.getAuthorizationHeader() },
      handler: (res: { authorizeCart: Mutation['authorizeCart'] }) => res?.authorizeCart?.authorized,
    });

export default useAuthorizeCart;
