import { URLS, USER_AUTHENTICATION_COOKIE, USER_PHONE_NUMBER, USER_SUBDOMAIN, UTM_COOKIE_NAME } from 'common/constants';
import { parseCookies, destroyCookie } from 'nookies';
import { CustomActionsRegistry, RenderingRegistry } from 'common/lib/registries';
import { changeRoute, logger, maskOrderFastUrl } from 'common/utils';
import { ErrorHandlers } from '../enums';
import ErrorsFactory from '../errors';
import ErrorsConfig from '../ErrorsConfig';

const ErrorHandler = (requestError: any): void => {
  try {
    const error = requestError?.response;
    const { status } = error || {};
    const isServerError = status >= 500;
    const { code = null } = !isServerError && error?.errors?.[0];
    const firstDigit = status?.toString()[0];
    const allCookies = parseCookies();
    const cookies = {
      authentication: allCookies[USER_AUTHENTICATION_COOKIE],
      phoneNumber: allCookies[USER_PHONE_NUMBER],
      subdomain: allCookies[USER_SUBDOMAIN],
      utmData: allCookies[UTM_COOKIE_NAME],
    };

    // Define searchable tags for logging
    const tags = {
      errorType: firstDigit === '5' ? 'server_error' : 'client_error',
      statusCode: status?.toString(),
    };

    const errorDetails = code ? ErrorsFactory.getError(code) : null;

    const { t } = ErrorsConfig;
    const isManualOrder = RenderingRegistry.getIsManualOrder();

    if (status === 401) {
      destroyCookie(null, USER_AUTHENTICATION_COOKIE, { path: '/' });
      localStorage?.clear();
    }
    if (firstDigit === '5' || !code) {
      const onServerErrorHandler = () => {
        logger.error({
          error,
          extraData: { requestError, error, technicalIssue: true, cookies },
          tags,
        });
        localStorage.clear();
        if (isManualOrder) {
          changeRoute(URLS.HOME);
        } else {
          window.location.replace(maskOrderFastUrl('/'));
        }
      };

      ErrorsConfig?.onModalError({
        isOpen: true,
        showCloseIcon: false,
        description: t('techError'),
        confirmButtonText: t('startOver'),
        cancelButtonText: t('refresh'),
        onConfirm: onServerErrorHandler,
        onCancel: () => window.location.reload(),
      });
      logger.error({ error, extraData: { requestError, error, technicalIssue: true } });
    } else {
      const {
        errorTitle,
        errorDescription,
        confirmText,
        cancelText,
        onConfirm,
        onCancel,
        shouldShowCloseIcon,
        errorAction,
        onConfirmCustomAction,
      } = errorDetails;
      const title = errorTitle ? t(`${errorTitle}`) : null;
      const description = errorDescription ? t(`${errorDescription}`) : null;

      const customErrorAction = CustomActionsRegistry.customActions.get(errorAction);

      if (errorDetails.errorType === ErrorHandlers.Modal) {
        const cancelButtonText = cancelText ? t(`${cancelText}`) : null;
        const confirmButtonText = confirmText ? t(`${confirmText}`) : null;
        const closeModal: () => void = () => ErrorsConfig?.onModalError({ isOpen: false });

        let onConfirmAction;
        if (confirmButtonText) {
          onConfirmAction = onConfirm || closeModal;
          if (onConfirmCustomAction) {
            const confirmCustomErrorAction = CustomActionsRegistry.customActions.get(onConfirmCustomAction);
            onConfirmAction = () => {
              confirmCustomErrorAction();
              closeModal();
            };
          }
        }

        if (customErrorAction) customErrorAction();

        ErrorsConfig?.onModalError({
          isOpen: true,
          showCloseIcon: shouldShowCloseIcon,
          title,
          description,
          confirmButtonText,
          cancelButtonText,
          onConfirm: onConfirmAction,
          onCancel,
          onClose: shouldShowCloseIcon ? () => ErrorsConfig?.onModalError({ isOpen: false }) : null,
        });
      } else if (errorDetails.errorType === ErrorHandlers.onErrorAction) {
        if (customErrorAction) customErrorAction();
      } else if (errorDetails.errorType === ErrorHandlers.SnackBar) {
        if (customErrorAction) customErrorAction();
        ErrorsConfig?.onSnackBarError({
          show: true,
          autoHide: false,
          title,
          message: description,
          onHide: () => ErrorsConfig?.onSnackBarError({ show: false }),
        });
      }
    }
  } catch (err) {
    const allCookies = parseCookies();
    const cookies = {
      authentication: allCookies[USER_AUTHENTICATION_COOKIE],
      phoneNumber: allCookies[USER_PHONE_NUMBER],
      subdomain: allCookies[USER_SUBDOMAIN],
      utmData: allCookies[UTM_COOKIE_NAME],
    };
    logger.error({
      error: err,
      extraData: { requestError, cookies },
      tags: { errorType: 'unexpected_error' },
    });
  }
};

export default ErrorHandler;
